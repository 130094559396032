import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage';
import router from '@/router';

export const useNotificationStore = defineStore('notificationStore', () => {
  const notifications = ref([]);
  const hasNewNotifications = ref(false);

  const notificationsCount = computed(() => notifications.value?.length);

  const hasNotifications = computed(() => notificationsCount.value > 0);

  function getNotifications() {
    notifications.value = getLocalstorageItem('notifications') || [];
  }

  function setNotifications(newNotifications) {
    setLocalstorageItem('notifications', [...newNotifications]);
    notifications.value = newNotifications;
  }

  function addNotification(notification) {
    notifications.value.unshift(notification);
    setLocalstorageItem('notifications', notifications.value);
    hasNewNotifications.value = true;
  }

  function removeNotification(index) {
    notifications.value.splice(index, 1);

    setLocalstorageItem('notifications', notifications.value);
  }

  function removeNotifications() {
    setNotifications([]);
  }

  function notificationAction(notificationData) {
    if (!notificationData) {
      return;
    }

    const action = notificationData.action;

    switch (action) {
      case 'offerDetail':
        router.push({
          name: action,
          params: {
            id: notificationData.id,
            version: notificationData.version,
          },
        });
        break;
      case 'issueDetail':
        router.push({
          name: action,
          params: {
            issue_id: notificationData.id,
          },
        });
        break;
      default:
        router.push({ name: action });
    }
  }

  return {
    notifications,
    notificationsCount,
    hasNotifications,
    hasNewNotifications,
    getNotifications,
    setNotifications,
    addNotification,
    removeNotification,
    removeNotifications,
    notificationAction,
  };
});
