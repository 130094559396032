import { defineStore } from 'pinia';
import { ref } from 'vue';
import apiClient from '@plugins/api-client';
import { getApiRoot } from '@/helpers/api';
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage';
import { getSubdomain } from '@/helpers/util';
import { getKeycloak } from '@/helpers/keycloak';

export const OrderStatuses = {
  New: 1,
  Prepayment1: 2,
  Prepayment: 3,
  Canceled: 4,
  Pending: 5,
  Partly_In_Production: 6,
  In_Production: 7,
  Partly_Produces: 8,
  Produced: 9,
  Partly_On_Delivery_Form: 10,
  On_Delivery_Form: 11,
  Partly_Completed: 12,
  Completed: 13,
  Rejected: 15,
};

export const useOrderStore = defineStore('orderStore', () => {
  const order = ref(null);
  const StatusExpirationDays = 10;

  async function getOrderByID(id) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/orders/${id}`,
        { headers },
      );
      if (response.data.errors.length === 0) {
        order.value = response.data.data;
      }
      else {
        throw new Error(response.data.errors);
      }
    }
    catch (error) {
      throw new Error(error);
    }
  }

  async function getOrderStatuses() {
    let OrderStatuses = getLocalstorageItem('OrderStatuses');
    if (OrderStatuses !== undefined) {
      return OrderStatuses;
    }
    return (await parseStatusResponse())?.OrderStatuses;
  }

  async function getInitialOrderStatus() {
    let InitialOrderStatus = getLocalstorageItem('InitialOrderStatus');
    if (InitialOrderStatus !== undefined) {
      return InitialOrderStatus;
    }
    return (await parseStatusResponse())?.InitialOrderStatus;
  }

  async function getFinalOrderStatus() {
    let FinalOrderStatus = getLocalstorageItem('FinalOrderStatus');
    if (FinalOrderStatus !== undefined) {
      return FinalOrderStatus;
    }
    return (await parseStatusResponse())?.FinalOrderStatus;
  }

  async function parseStatusResponse() {
    try {
      const response = await apiClient.get(
        `${getApiRoot()}/orders/statuses`, {
          params: {
            subDomain: getSubdomain(),
            lang: 'nl-BE',
          },
        },
      );
      if (response.data.errors.length === 0) {
        let OrderStatuses = {};
        let InitialOrderStatus = null;
        let FinalOrderStatus = null;
        const data = response.data.data;
        for (const status of data) {
          OrderStatuses[status.status.split(' ').join('_')] = status.id;
        }
        InitialOrderStatus = data.filter((status) => status.initial)[0]?.id;
        FinalOrderStatus = data.filter((status) => status.final)[0]?.id;

        setLocalstorageItem('OrderStatuses', OrderStatuses, StatusExpirationDays);
        setLocalstorageItem('InitialOrderStatus', InitialOrderStatus, StatusExpirationDays);
        setLocalstorageItem('FinalOrderStatus', FinalOrderStatus, StatusExpirationDays);

        return {
          OrderStatuses: OrderStatuses,
          InitialOrderStatus: InitialOrderStatus,
          FinalOrderStatus: FinalOrderStatus,
        };
      }
      else {
        throw new Error(response.data.errors);
      }
    }
    catch (error) {
      throw new Error(error);
    }
  }

  async function signOrderDelivery(orderID, data) {
    const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
    const response = await apiClient.post(
      `${getApiRoot()}/orders/${orderID}/sign`,
      data,
      { headers },
    );
    if (response.status === 200) {
      return true;
    }
    else {
      throw new Error(response.data.message);
    }
  }

  return {
    order,
    getOrderByID,
    getOrderStatuses,
    getInitialOrderStatus,
    getFinalOrderStatus,
    signOrderDelivery,
  };
});
