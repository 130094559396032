<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.2991 9.795C21.6379 9.72606 21.9425 9.54219 22.1613 9.27449C22.3802 9.00679 22.4998 8.6717 22.5 8.32594V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5H3C2.60218 4.5 2.22065 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V8.32594C1.50019 8.6717 1.61983 9.00679 1.83867 9.27449C2.05751 9.54219 2.36212 9.72606 2.70094 9.795C3.20757 9.90006 3.66251 10.1765 3.98912 10.5778C4.31573 10.9791 4.49406 11.4807 4.49406 11.9981C4.49406 12.5155 4.31573 13.0171 3.98912 13.4184C3.66251 13.8197 3.20757 14.0962 2.70094 14.2013C2.36148 14.2703 2.0564 14.4547 1.83748 14.7232C1.61857 14.9917 1.49933 15.3277 1.5 15.6741V18C1.5 18.3978 1.65804 18.7794 1.93934 19.0607C2.22065 19.342 2.60218 19.5 3 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V15.6741C22.4998 15.3283 22.3802 14.9932 22.1613 14.7255C21.9425 14.4578 21.6379 14.2739 21.2991 14.205C20.7924 14.0999 20.3375 13.8235 20.0109 13.4222C19.6843 13.0209 19.5059 12.5193 19.5059 12.0019C19.5059 11.4845 19.6843 10.9829 20.0109 10.5816C20.3375 10.1803 20.7924 9.90381 21.2991 9.79875V9.795ZM3 15.675C3.84722 15.5024 4.60876 15.0424 5.15573 14.3728C5.70269 13.7031 6.00146 12.8651 6.00146 12.0005C6.00146 11.1359 5.70269 10.2978 5.15573 9.62819C4.60876 8.95857 3.84722 8.49852 3 8.32594V6H8.25V18H3V15.675Z" fill="#A0D52D" />
  </svg>
</template>

<style scoped>
path{
    stroke: rgb(var(--v-theme-primary));
    fill: rgb(var(--v-theme-primary));
}
</style>
