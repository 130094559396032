import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSnackbarStore = defineStore('snackbarStore', () => {
  const showSnackBar = ref(false);
  const snackbarColour = ref('');
  const snackbarText = ref('');

  function showSuccess(text) {
    snackbarColour.value = 'succes500';
    snackbarText.value = text;
    showSnackBar.value = true;
  }

  function showError(text) {
    snackbarColour.value = 'error500';
    snackbarText.value = text;
    showSnackBar.value = true;
  }

  return {
    showSnackBar,
    snackbarColour,
    snackbarText,
    showSuccess,
    showError,
  };
});
