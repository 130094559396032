import apiClient from '@/plugins/api-client';
import { getKeycloak } from '@/helpers/keycloak';
import { getApiRoot } from '@/helpers/api';
import { useSnackbarStore } from '@/store/snackbar';
import { useI18n } from 'vue-i18n';

export function useApi() {
  const { t } = useI18n();
  const { showError } = useSnackbarStore();

  const apiGet = async (url, responseType = 'json', shouldShowError = true) => {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/${url}`,
        { headers, responseType },
      );

      return response.data.data ?? response;
    }
    catch (error) {
      if (shouldShowError) {
        showError(error.response.data?.message ?? t('genericServerError'));
      }
      else {
        throw new Error(error);
      }
    }
  };

  const apiPost = async (url, data, shouldShowError = true) => {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.post(
        `${getApiRoot()}/${url}`,
        data,
        { headers },
      );

      return response.data.data;
    }
    catch (error) {
      if (shouldShowError) {
        showError(error.response.data?.message ?? t('genericServerError'));
      }
      else {
        throw new Error(error);
      }
    }
  };

  return {
    apiGet,
    apiPost,
  };
}
