<template>
  <v-snackbar v-model="showSnackBar" :color="snackbarColour">
    {{ snackbarText }}
    <template #actions>
      <v-btn
        icon="mdi-close"
        @click="showSnackBar = false"
      />
    </template>
  </v-snackbar>
</template>

<script setup>
import { useSnackbarStore } from '@/store/snackbar';
import { storeToRefs } from 'pinia';

const snackbarStore = useSnackbarStore();
let {
  snackbarText,
  snackbarColour,
  showSnackBar,
} = storeToRefs(snackbarStore);

</script>
