/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify';
import router from '@/router';
import i18n from '@i18n';
import VueSignaturePad from 'vue-signature-pad';

import { createPinia } from 'pinia';

const pinia = createPinia();

export function registerPlugins(app) {
  app
    .use(i18n)
    .use(pinia)
    .use(vuetify)
    .use(router)
    .use(VueSignaturePad);
}
