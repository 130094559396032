export function getSessionStorageItem(key) {
  let item = JSON.parse(sessionStorage.getItem(key));
  if (item) {
    const now = new Date();
    const expDate = new Date(item.expDate);
    if (now > expDate) {
      return undefined;
    }
    else {
      return item[key];
    }
  }
  return undefined;
}

export function setSessionstorageItem(key, item, daysTillExpiration = 1) {
  let date = new Date(Date.now() + (daysTillExpiration * 24 * 3600000));
  sessionStorage.setItem(
    key,
    JSON.stringify(
      {
        ...{ expDate: date },
        ...{ [key]: item },
      },
    ),
  );
}

export function removeSessionstorageItem(key) {
  sessionStorage.removeItem(key);
}

export function emptySessionstorage() {
  for (const [key] in Object.entries(sessionStorage)) {
    sessionStorage.removeItem(key);
  }
}
