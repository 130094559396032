<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.3872 11.1168C19.36 11.0016 19.3048 10.8949 19.2264 10.8062C19.148 10.7175 19.049 10.6495 18.938 10.6083L13.7621 8.66674L15.0792 2.07849C15.109 1.92542 15.0883 1.7668 15.02 1.62656C14.9518 1.48633 14.8398 1.37209 14.701 1.3011C14.5621 1.2301 14.4039 1.20619 14.2503 1.23297C14.0967 1.25976 13.9559 1.33579 13.8493 1.44959L3.78676 12.2308C3.70503 12.317 3.64591 12.422 3.61468 12.5365C3.58344 12.6511 3.58107 12.7715 3.60776 12.8872C3.63446 13.0029 3.68939 13.1102 3.76766 13.1995C3.84593 13.2887 3.94509 13.3572 4.05629 13.3988L9.23399 15.3403L7.92047 21.9214C7.89065 22.0745 7.91142 22.2331 7.97965 22.3733C8.04789 22.5135 8.15988 22.6278 8.29874 22.6988C8.43759 22.7698 8.59577 22.7937 8.74941 22.7669C8.90304 22.7401 9.0438 22.6641 9.15043 22.5503L19.2129 11.769C19.2932 11.6829 19.351 11.5784 19.3814 11.4646C19.4118 11.3509 19.4138 11.2315 19.3872 11.1168ZM9.82606 19.7265L10.7667 15.0205C10.8004 14.8536 10.7737 14.6802 10.6914 14.5311C10.6092 14.382 10.4767 14.267 10.3175 14.2065L5.57016 12.4231L13.1727 4.27787L12.233 8.98389C12.1993 9.15079 12.226 9.32422 12.3083 9.47329C12.3905 9.62236 12.523 9.73738 12.6822 9.79787L17.4259 11.5768L9.82606 19.7265Z" fill="#0E0F0B" />
  </svg>
</template>
